<template>
  <div v-show="isInit">
    <div class="contract_container">
      <div class="top">{{ navText }}</div>
      <div class="container" :class="{'nofoot': outOrderDetail && (outOrderDetail.orderOutStatus != 3 && outOrderDetail.orderOutStatus != 4)}">
				<div id="zoomBox">
          <div class="contract_main">
            <div class="tips">*如需打印，请至平台网站下载表单打印</div>
            <div class="summary_title">
              <span class="name" v-if="userInfo">{{ userInfo.departName }}（送货清单）</span>
              <div class="sum-rt">
                <div class="date">
                  日期：<span v-if="outOrderDetail && outOrderDetail.orderTime">{{ outOrderDetail.orderTime.split(" ")[0]
                  }}</span>
                </div>
                <div class="order-num">
                  订单号：<span v-if="outOrderDetail && outOrderDetail.orderNo">{{ outOrderDetail.orderNo }}</span>
                </div>
              </div>
            </div>
            <template v-if="customerInfo">
              <div class="userinfo">
                <div class="title">客户信息</div>
                <div class="userinfo_cont">
                  <div v-if="customerInfo">
                    公司名称：{{ customerInfo.departName || '-' }}<br />
                    联系地址：{{ customerInfo.address }}
                  </div>
                  <div class="rt" v-if="customerInfo">
                    联系人：{{ customerInfo.customerName }}<br />
                    联系电话：{{ customerInfo.phone }}
                  </div>
                </div>
              </div>
            </template>

            <div class="project_info" v-if="orderAddressInfo">
              <div class="title">项目信息</div>
              <div class="project_main">
                <div>
                  项目名称：{{ orderAddressInfo.projectName }}<br />
                  送货地址：{{ orderAddressInfo.provinceName }}{{ orderAddressInfo.cityName }}{{ orderAddressInfo.districtName }}
                  {{ orderAddressInfo.address }}
                </div>
                <div style="text-align: right;">
                  收货人：{{ orderAddressInfo.uaRealName }}<br />
                  联系电话：{{ orderAddressInfo.uaMobile }}
                </div>
              </div>
            </div>


            <div class="product_info">
              <table border="0" cellpadding="0" cellspacing="0" class="mytable1">
                <tr>
                  <td width="24%" class="table_bg">产品名称</td>
                  <td width="24%" class="table_bg">规格型号</td>
                  <td width="10%" class="table_bg">单位</td>
                  <td width="10%" class="table_bg">出货数</td>
                  <td width="16%" class="table_bg">签收数</td>
                  <td width="16%" class="table_bg">签收状态</td>
                </tr>
                <tr v-for="(item, index) in productList" :key="index">
                  <td>{{ item.productName }}</td>
                  <td>{{ item.formulaContent }}</td>
                  <td>{{ item.orderUnitName }}</td>
                  <td>{{ item.orderOutNum }}</td>
                  <td>
                    <input 
                        type="number" 
                        v-model="item.signNum" 
                        :disabled="outOrderDetail.orderOutStatus == 3 || outOrderDetail.orderOutStatus == 4"
                        class="signnput"
                       />
                  </td>
                  <td>
                    <template v-if="item.outSignStatus == 1">待签收</template>
                    <template v-if="item.outSignStatus == 2">已签收</template>
                    <template v-if="item.outSignStatus == 3">部分签收</template>
                  </td>
                </tr>
                <tr class="table_foot">
                  <td colspan="3" style="text-align: left;padding-left: 20rpx;"><span class="txt">合计：</span>
                  </td>
                  <td><span class="txt">{{ totalCount }}</span></td>
                  <td colspan="2" style="text-align: right;padding-right: 20rpx;">
                    <template v-if="status == 1">
                      <span class="txt red">待提货</span>
                    </template>
                    <template v-if="status == 2">
                      <span class="txt red">待签收</span>
                    </template>
                    <template v-if="status == 3">
                      <span class="txt red">部分签收</span>
                    </template>
                    <template v-if="status == 4">
                      <span class="txt red">已签收</span>
                    </template>
                    <template v-if="status == 5">
                      <span class="txt red">自提签收</span>
                    </template>
                  </td>
                </tr>
              </table>
              <div class="summary_f">
                <div class="lf">
                  <span class="name" v-if="userInfo">{{ userInfo.departName }}</span>
                  <div class="contact" v-if="userInfo">
                    联系人：{{ userInfo.realname }}，地址：{{ userInfo.address }}<br />
                    电话：{{ userInfo.phone }}
                  </div>
                  <div>
                    <div>经营范围：</div>
                    <div></div>
                  </div>
                </div>
                <div class="rt">
                  <img class="qr" src="../assets/qr.jpg" />
                </div>
              </div>
            </div>
            <div class="transport" v-if="outOrderDetail">
              <div class="t_u cy">
                <span>承运方：{{ outOrderDetail.expressName }}</span>
                <template v-if="(outOrderDetail.orderOutStatus != 3 && outOrderDetail.orderOutStatus != 4) && (orderAddressInfo.distributionType == 1)">
                  <span class="rt" @click="showYSPopup()">编辑运输信息</span>
                </template>
              </div>
              <div class="t_u">
                联系电话：<span class="blue">{{ outOrderDetail.expressMobile }}</span>
              </div>
              <div class="t_u ordernum">
                <span>运输单号：{{ outOrderDetail.shippingNo }}</span>
                <span class="red" @click="copyTxt()">一键复制运输单号</span>
              </div>
            </div>
            <div class='bar'></div>
            <div class="sign">
              <div class="sign_main">
                <template v-if="orderAddressInfo && orderAddressInfo.distributionType == 1">
                  <div class="sign_unit">
                    <span class="title">提货签字</span>
                    <div class="sign_content" @click="sign(1)">
                      <img :src="outOrderDetail.pickUpImg" v-if="outOrderDetail && outOrderDetail.pickUpImg"
                        class="signimg" />
                    </div>
                    <div class="date" v-if="outOrderDetail && outOrderDetail.pickUpTime">{{ outOrderDetail.pickUpTime }}</div>
                  </div>
                </template>
                <div class="sign_unit">
                  <span class="title">收货签字</span>
                  <div class="sign_content" @click="sign(2)">
                    <img :src="outOrderDetail.receiverImg" v-if="outOrderDetail && outOrderDetail.receiverImg"
                      class="signimg" />
                  </div>
                  <div class="date" v-if="outOrderDetail && outOrderDetail.receiverTime">{{ outOrderDetail.receiverTime }}
                  </div>
                </div>
              </div>
              <template v-if="orderAddressInfo && orderAddressInfo.distributionType == 1">
                <span class="signtips">
                  *注:请核对签收,如有误差请联络发货方,一旦签收将视为确认无误。<br />
                  *注：提货签字后示即为已验收，一旦签收将视为确认无误。
                </span>
              </template>
              <template v-if="orderAddressInfo && orderAddressInfo.distributionType != 1">
                <span class="signtips">
                  *注:请核对签收,如有误差请联络发货方,一旦签收将视为确认无误。
                </span>
              </template>

              <div class="mark" v-if="ysVisible">
                <div class="popbox" @touchmove.stop.prevent="">
                  <div class="title">编辑运输信息</div>
                  <div class="p-main">
                    <div class="p-u">
                      <span class="bt">承运方</span>
                      <input type="text" class="p-ipt" v-model="expressName" />
                    </div>
                    <div class="p-u">
                      <span class="bt">联系电话</span>
                      <input type="text" class="p-ipt" v-model="expressMobile" />
                    </div>
                    <div class="p-u">
                      <span class="bt">运输单号</span>
                      <input type="text" class="p-ipt" v-model="shippingNo" />
                    </div>
                  </div>
                  <div class="popfoot">
                    <div class="foot-unit" @click.stop="editYS()">确认</div>
                    <div class="foot-unit red" @click.stop="hideYSPopup">取消</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Autograph @signAutoGraph="signAutoGraph" ref="autoGraph" :hasPass="false" :hasTips="true" :tipText="tipText">
        </Autograph>
        </div>
      </div>
        <template v-if="outOrderDetail && outOrderDetail.orderOutStatus == 3">
          <div class="footbtn grey">已部分签收</div>
        </template>
        <template v-if="outOrderDetail && outOrderDetail.orderOutStatus == 4">
          <div class="footbtn grey">已签收</div>
        </template>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import {
  getQueryString,
  base64toFile
} from "@/common/comm.js"
import { EasyScroller } from 'easyscroller'
import Autograph from '@/components/Autograph.vue'
import $http from "@/common/api/request.js"
export default {
  data() {
    return {
      isInit: false,
      tipText: "签字后不可再修改<br/>如需修改，请重新生成出货单重新签字",
      signType: 1,
      navText: "",
      statusBarHeight: 0,
      navigatorBarHeight: 0,
      topH: 0,
      status: "",
      navigatorTitle: "",
      isShowMenu: false,
      sellerIsSign: false, // 卖方是否签字
      shareQueryParams: "",
      orderId: "",
      ordOutId: "",
      contId: "",
      contractStatus: "",
      outOrderDetail: null,
      customerInfo: null,
      orderAddressInfo: null,
      userInfo: null,
      productList: [],
      totalCount: 0,
      totalPrice: 0,
      upperCaseTotalPrice: "",
      protocolContent: "",
      isShowProtocolDialog: false,
      saleSignImg: "",
      saleSignTime: "",
      buySignImg: "",
      buySignTime: "",
      sealImg: "",
      salt: "",
      ysVisible: false,
      expressName: "",
      expressMobile: "",
      shippingNo: "",
      scroller: null
    }
  },
  components: {
    Autograph
  },
  mounted() {
    let request = getQueryString()
    this.orderId = request['ordId']
    this.ordOutId = request['ordOutId']
    this.salt = request['salt']
    this.getOrderOutAllDetail()
    this.setScroller()
  },
  beforeDestroy() {
		if (this.scroller) this.scroller.destroy()
	},
  methods: {
    getOrderOutAllDetail() {
      let params = {
        url: '/sys/order/orderOutPreview?ordOutId=' + this.ordOutId + "&salt=" + this.salt + '&ordId=' + this.orderId,
        method: 'GET',
        header: {
          'Content-Type': 'application/json'
        }
      }
      $http.request(params).then((res) => {
        this.isInit = true
        if (res.code == 200) {
          this.outOrderDetail = res.result
          this.customerInfo = res.result.customerInfoVO
          this.userInfo = res.result.userInfoVO
          this.orderAddressInfo = res.result.orderAddressVO
          this.productList = res.result.orderOutItemVOList
          this.calcCount()
          this.status = res.result.orderOutStatus
          this.navText = "出货单号" + this.outOrderDetail.orderOutNo
          document.title = this.navText
        }
      }).catch(() => { })
    },
    getAllSignStatusInfo(orderOutItemVOList) {
      let status = 1
      let totalOrderOutNum = 0
      let totalSignNum = 0
      if (orderOutItemVOList && orderOutItemVOList.length > 0) {
        for (let i = 0; i < orderOutItemVOList.length; i++) {
          totalOrderOutNum += orderOutItemVOList[i].orderOutNum
          totalSignNum += orderOutItemVOList[i].signNum
        }
        if (totalSignNum == totalOrderOutNum) {
          status = 2
        }
        if (totalSignNum == 0) {
          status = 1
        }
        if (totalSignNum > 0 && totalSignNum < totalOrderOutNum) {
          status = 3
        }
        return status
      } else {
        return status
      }
    },
    setScroller() {
			const ele = document.querySelector('#zoomBox'); //缩放盒子
			this.scroller = new EasyScroller(ele, {
				scrollingX: true,
				scrollingY: true,
				zooming: true,
				minZoom: 1,    //最小缩放
				maxZoom: 4,    //最大缩放
				zoomLevel: 1, //初始值缩放
				bouncing: false
			});
		},
    calcCount() {
      this.totalCount = 0
      this.totalPrice = 0
      this.productList.forEach((item) => {
        this.totalCount += item.orderOutNum
      })
    },
    // 显示编辑运输信息弹窗
    showYSPopup() {
      this.expressName = this.outOrderDetail.expressName
      this.expressMobile = this.outOrderDetail.expressMobile
      this.shippingNo = this.outOrderDetail.shippingNo
      this.ysVisible = true
    },
    hideYSPopup() {
      this.ysVisible = false
    },
    handlerProduct(productList) {
      let arr = []
      if (productList && productList.length > 0) {
        arr = productList.map((product) => {
          return { id: product.id, signNum: product.signNum ? +product.signNum : 0 }
        })
      }
      return arr;
    },
    sign(type) {
      this.signType = type
      if (type == 1 && this.status == 1) {
        this.$refs.autoGraph.show()
      }
      if (type == 2 && (this.status != 3 && this.status != 4)) {
        if (this.status == 1) return
        this.$refs.autoGraph.show()
      }
    },
    signAutoGraph(signBase64Path) {
      let _this = this
      let file = base64toFile(signBase64Path, new Date().getTime() + Math.random() + ".jpg")
      const formData = new FormData();
      formData.append("file", file)
      axios({
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "https://fengyisuan.com/app/sys/common/upload",
        data: formData,
      })
        .then(function (res) {
          if (res.data.code == 200) {
            _this.outSignature(res.data.result)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 签字
    outSignature(signImgUrl) {
      let _this = this
      let params = {
        url: '/sys/order/outSignature',
        method: 'POST',
        header: {
          'Content-Type': 'application/json'
        },
        data: {
          id: this.ordOutId,
          orderOutItemDTOList: this.handlerProduct(this.productList),
          signImgUrl,
          type: this.signType
        }
      }
      $http.request(params).then((res) => {
        if (res.code == 200) {
          _this.$refs.autoGraph.hide()
          _this.getOrderOutAllDetail()
        } else {
          alert(res.message)
        }
      }).catch(() => { })
    },
    editYS() {
      if (this.expressName == '') {
        return alert("请输入承运方")
      }
      if (this.expressMobile == '') {
        return alert("请输入联系电话")
      } else {
        if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/i.test(this.expressMobile) == false) {
            return alert("联系电话格式不正确")
        }
      }
      if (this.shippingNo == '') {
        return alert("请输入运输单号")
      }
      this.updateExpress()
    },
    updateExpress() {
				let params = {
					url: '/sys/order/updateExpress?ordOutId=' + this.ordOutId,
					method: 'POST',
					header: {
						'Content-Type': 'application/json'
					},
					data: {
						expressMobile: this.expressMobile,
						expressName: this.expressName,
						shippingNo: this.shippingNo,
						id: this.ordOutId
					}
				}
				$http.request(params).then((res) => {
					if (res.code == 200) {
            alert("编辑成功")
						this.hideYSPopup()
						this.getOrderOutAllDetail()
					} else {
            alert(res.message)
					}
				})
			},
    copyTxt() {
      let str = this.outOrderDetail.shippingNo
      navigator.clipboard.writeText(str).then(() => {
        alert("复制成功")
      }).catch(() => {
        alert("复制失败")
      })
    }
  }
}

</script>
<style scoped>
html,
body {
  padding: 0;
  margin: 0 !important;
}

.contract_container {
  padding-top: 44px;
}

.no-pad {
  padding-bottom: 0 !important;
}
.container {
	height: calc(100vh - 94px);
	overflow: hidden;
}
.container.nofoot {
  height: calc(100vh - 44px);
	overflow: hidden;
}
.top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 44px;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-bar {
  width: 100%;
}

.navgator-bar {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  /* background-color: #0072DC; */
  color: #242424;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.contract_main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* padding-top: 44px; */
}

.tips {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #9F9F9F;
  padding: 10px 0;
}

.contract_title {
  font-size: 18rpx;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
}

.mytable {
  width: 100%;
  border-top: 1px solid #9F9F9F;
  border-bottom: 1px solid #9F9F9F;
}

.mytable td {
  font-size: 12px;
  border-right: 1px solid #9F9F9F;
  padding: 10px;
  vertical-align: top;
  vertical-align: middle !important;
}

.top_line {
  border-top: 1px solid #9F9F9F;
}

.mytable .b_in {
  display: flex;
  justify-content: space-between;
}

.mytable .noborder {
  border: 0;
}

.project_info {
  display: flex;
  flex-direction: column;
  padding: 7px 8px;
}

.project_info .title {
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 0;
}

.project_main {
  display: flex;
  justify-content: space-between;
  font-size: 6px;
}

.mytable1 {
  width: 100%;
  border-top: 1px solid #9F9F9F;
  /* border-bottom: 1px solid #9F9F9F; */
}

.mytable1 td {
  font-size: 6px;
  border-bottom: 1px solid #9F9F9F;
  padding: 5px;
  vertical-align: top;
  text-align: center;
  vertical-align: middle !important;
}

.table_bg {
  background-color: #F6F6F6;
}

.f_bold {
  font-weight: bold;
}

.total_price {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.userinfo {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.userinfo .title {
  font-weight: bold;
  font-size: 10px;
  padding-bottom: 0px;
}

.userinfo_cont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 6px;
}

.userinfo_cont .rt {
  text-align: right;
}

.sum-rt {
  display: flex;
  font-size: 6px;
}

.summary_title {
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.summary_title .name {
  font-size: 6px;
  font-weight: bold;
}

.summary_title .date {
  font-size: 6px;
  margin-right: 10px;
}

.order-num {
  font-size: 6px;
}

.signnput {
  width: 100%;
  height: 20px;
  background: #F5F5F5;
  border: 1px solid #D5D5D5;
  outline: none;
  text-align: center;
}

.summary_f {
  display: flex;
  justify-content: space-between;
  font-size: 6px;
  padding: 10px 10px 24px 10px;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.summary_f .lf {
  padding-right: 8px;
}

.summary_f .name {
  font-size: 9px;
  font-weight: bold;
}

.summary_f .contact {
  margin: 6px 0 10px 0;
}

.summary_f .rt {
  flex: 0 0 80px;
}

.transport {
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  color: #333;
  font-size: 13px;
}

.transport .t_u {
  padding-bottom: 10px;
}

.transport .t_u:last-child {
  padding-bottom: 0;
}

.cy {
  display: flex;
  justify-content: space-between;
}

.cy .rt {
  color: #0072DC;
}

.p-body {
  display: flex;
  flex-direction: column;
}

.p-body .title {
  margin-bottom: 15px;
}

.transport .ordernum {
  display: flex;
  justify-content: space-between;
}

.sign {
  margin-top: 10px;
  background-color: #fff;
  padding: 20px 10px;
}

.sign_main {
  display: flex;
  justify-content: space-between;
}

.sign_unit {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.sign_unit .sign_content {
  width: 100%;
  height: 110px;
  background: #F5F5F5;
  border: 1px solid #A3A3A3;
  /* margin-bottom: 15px; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_unit .date {
  text-align: center;
  font-size: 12px;
}

.sign_unit .title {
  font-size: 13px;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.txtinfo {
  font-size: 15px;
}

.ft {
  width: 100%;
  display: flex;
}

.signimg {
  width: 88px;
  height: 67px;
}
.table_foot td {
  background-color: #F6F6F6;
  padding: 10px;
}

.table_foot .txt {
  font-weight: bolder;
}

.bar {
  height: 4px;
  background: url("../assets/bar_bg.jpg") repeat-x left center;
  background-size: 85px 5px;
}

.p-main {
  display: flex;
  flex-direction: column;
}

.p-u {
  display: flex;
  align-items: center;
  padding: 10px 15px 5px 15px;
}

.p-u .bt {
  flex: 0 0 70px;
  font-size: 14px;
  text-align: left;
}

.p-u .p-ipt {
  flex: 1;
  height: 34px;
  background: #F5F5F5;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
}

.signtips {
  text-align: center;
  font-size: 11px;
  color: #ff0000;
  padding: 15px 0 14px 0;
}

.red {
  color: red;
}

.qr {
  width: 80px;
  height: 80px;
}

.footbtn {
  width: 100%;
  height: 49px;
  background-color: #2BA246;
  color: #fff;
  font-size: 14px;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  line-height: 49px;
}

.footbtn.grey {
  background-color: #6D6D6D;
}

.mark {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
}

.popbox {
  width: 80%;
  height: 260px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -130px;
  margin-left: -40%;
  border-radius: 5px;
}

.popbox .title {
  text-align: center;
  padding: 25px 0 18px 0;
  font-size: 15px;
}

.popfoot {
  display: flex;
  height: 38px;
}

.foot-unit {
  flex: 1;
  height: 38px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}</style>